import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';
import PasswordMatchRequirements from 'helpers/utils/passwordMatchRequirements';

export const RetryPassword = ({
  toRetrieveUsername,
  success,
  error,
  errMessage,
  newPassword,
  showPassword,
  handleResetPassword,
  inputRef,
  handleInputFocus,
  handleInputBlur,
  newPasswordForUser,
  newPasswordError,
  errorMessage,
  setShowPassword,
  passwordRequirements,
  receiveOTP,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <>
      <div>
        <div>
          {' '}
          <div className="flex justify-between">
            <h3 className="mb-6 text-lg font-semibold text-black-900" id="password-reset-heading">
              {formatAccountMessage({ id: 'reset.password', defaultMessage: 'Password Reset' })}
            </h3>
            <button type="button" className="absolute right-6 top-10 " onClick={toRetrieveUsername} aria-label="Close">
              <GrClose />
            </button>
          </div>
        </div>
        {success && (
          <section className="my-2" aria-live="assertive">
            <p className="text-sm text-green-900" id="success-message">
              {success}
            </p>
          </section>
        )}
        <div className="mt-12">
          {error && (
            <section className="my-2" aria-live="assertive">
              <p className="text-sm text-red-900" id="error-message">
                {error}
              </p>
            </section>
          )}
          {errMessage && newPassword && (
            <section className="my-2" aria-live="assertive">
              <p className="text-sm text-red-900" id="err-message">
                {errMessage}
              </p>
            </section>
          )}
          <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="password">
            {formatAccountMessage({ id: 'new.password', defaultMessage: 'New Password' })}
            <span className="ml-2 text-red-600"> {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}</span>
          </label>
          <div className="relative w-full rounded-sm border border-gray-600 focus:border focus:border-gray-900">
            <input
              value={newPassword}
              type={showPassword ? 'text' : 'password'}
              placeholder="••••••••••••••••"
              className="w-3/4 rounded-sm border-0 placeholder:text-gray-400"
              required
              onChange={handleResetPassword}
              aria-describedby="password-reset-heading success-message error-message err-message password-requirements"
              ref={inputRef}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />

            <div className="absolute right-3 top-2 flex">
              <button
                className="flex items-center text-affes-sm "
                onClick={(event) => {
                  event.stopPropagation();
                  setShowPassword(!showPassword);
                }}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                type="button"
              >
                {showPassword ? <EyeOffIcon className="mr-2 h-4 w-4" /> : <EyeIcon className="mr-2 h-4 w-4" />}
                {showPassword ? 'HIDE' : 'SHOW'}
              </button>
            </div>
          </div>
          {errorMessage && (
            <p className="text-red-600" id="password-requirements">
              {errorMessage}
            </p>
          )}
          {passwordRequirements && PasswordMatchRequirements(newPassword, receiveOTP?.passwordResetToken?.userName)}{' '}
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
          {newPasswordError && (
            <p className="text-red-600" id="password-error">
              {newPasswordError}
            </p>
          )}
        </div>

        <div className="pt-14">
          <button
            className="w-full rounded-sm bg-blue-900 px-4 py-2 text-white"
            onClick={newPasswordForUser}
            type="button"
          >
            Continue
          </button>
        </div>
        <div className="pt-6 text-center">
          {' '}
          <button type="button" className="w-1/4 text-accent-400" onClick={toRetrieveUsername}>
            {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </button>
        </div>
      </div>
    </>
  );
};
