import { useFormat } from 'helpers/hooks/useFormat';

export const SentUserName = ({ resetUsername, backToLogin }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <div>
      {resetUsername ? (
        <div className="mb-8">
          <h3 className="absolute top-10 text-lg font-semibold text-black-900">
            {formatAccountMessage({ id: 'Forgot.username', defaultMessage: 'Retrieve Username' })}
          </h3>
        </div>
      ) : (
        <div className="mb-8">
          <h3 className="absolute top-10 text-lg font-semibold text-black-900">
            {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
          </h3>
        </div>
      )}
      <div>
        <div className="flex-col py-10">
          <h3 className="text-md mb-4 font-semibold text-black-900" id="username-sent-message">
            {formatAccountMessage({
              id: 'username.sent',
              defaultMessage: 'Your username has been sent to your email address',
            })}
          </h3>
          <p id="continue-account-message">
            {formatAccountMessage({
              id: 'continue.account',
              defaultMessage: 'Please select Continue to log in to your account.',
            })}
          </p>
        </div>
      </div>
      <div>
        <button
          className="w-1/4 rounded-sm bg-blue-900 px-4 py-2 text-white"
          onClick={backToLogin}
          aria-labelledby="username-sent-message continue-account-message"
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};
